import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import Blocks from '../components/blocks';
import WackyStory from '../components/BlockLibrary/Wacky/WackyStory';
import '../sass/main.scss';
import { titleCase } from '../components/utilities';
class wackyStoryTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);

               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
          const trustpilotScript = document.createElement('script');
          trustpilotScript.type = 'text/javascript';
          trustpilotScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          trustpilotScript.async = true
          document.head.appendChild(trustpilotScript)
          trustpilotScript.onload = function () {
               var trustbox = document.getElementsByClassName('trustpilot-widget');
               window.Trustpilot.loadFromElement(trustbox);
          };
     }

     render() {
          const story = get(this.props, 'data.contentfulWackyPetStory');
          const path = get(this.props, '*');
          const page = get(this.props, 'data.contentfulWackyPetStory');

          const getDefaultSeo = () => {
               const year = page.category?.slug?.split('-')?.[0];
               let petType = page.category?.slug?.split('-')?.[1];

               if (petType === 'exotic') {
                    petType = 'exotic pet';
               }

               const titleCasePetType = titleCase(petType);

               let defaultSeoTitle = `${year} Wacky ${titleCasePetType} Name Nominees | ${page.petName}`;
               let defaultSeoMetaDescription = `Click to meet ${page.petName}, one of Nationwide's proud nominees for the coveted title of ${year} Wacky ${titleCasePetType} Names.`;

               return { defaultSeoTitle, defaultSeoMetaDescription };
          }

          const { defaultSeoTitle, defaultSeoMetaDescription } = getDefaultSeo();

          return (
               <Layout>
                    <Seo
                         pageName={page.omniturePageNameLegacy || page.contentName}
                         specificPhone={page.specificPhone}
                         trackingCode={page.trackingCode}
                         title={story.seoTitleTag || defaultSeoTitle}
                         path={path}
                         canonical={story.seoCanonicalUrl ? story.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={story.seoMetaDescription?.seoMetaDescription || defaultSeoMetaDescription}
                         robots={story.robotsDirective ? story.robotsDirective.robotsDirective : ''}
                         image={story.socialImage ? story.socialImage : story.featuredImage}
                         showAntiFlickerSnippet={story.showAntiFlickerSnippet}
                    />
                    <Header header={story.header}></Header>
                    <main id="main-content" role="main">
                         <div className="container">
                              <div className="article-page-breadcrumb wacky-breadcrumb">
                                   <a href="/wackypetnames/">Home</a>/<a href={'/wackypetnames/' + story.category.slug}>{story.category.name}</a>/<span>{story.petName}</span>
                              </div>
                         </div>
                         <WackyStory story={story} />
                         {story.rows && <Blocks blocks={story.rows}></Blocks>}
                    </main>
                    <Footer footer={story.footer}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};

const mapStateToProps = (state) => {
     return {
          state
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(wackyStoryTemplate);

export const pageQuery = graphql`
     query wackyStoryBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulWackyPetStory(slug: { eq: $slug }) {
               ...ContentfulWackyPetStoryFields
          }
     }
`;
